import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-layout.js";
import SEO from "../components/seo";
import GithubCalendar from "react-github-calendar";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About me" description="Infomation about author(wuxiaobao24)" mdxType="SEO" />
    <h2>{`个人信息`}</h2>
    <ul>
      <li parentName="ul">{`吴坤汉/男/1997`}</li>
      <li parentName="ul">{`本科/深圳大学计算机科学与技术 2015 级`}</li>
      <li parentName="ul">{`研究生/深圳大学软件工程 2019 级`}</li>
      <li parentName="ul">{`Blog：`}<a parentName="li" {...{
          "href": "https://wuxiaobai24.github.io"
        }}>{`https://wuxiaobai24.github.io`}</a></li>
      <li parentName="ul">{`Github：`}<a parentName="li" {...{
          "href": "https://github.com/wuxiaobai24"
        }}>{`https://github.com/wuxiaobai24`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`联系方式`}</h2>
    <ul>
      <li parentName="ul">{`手机：MTM3NTEwMTU5MTk=`}</li>
      <li parentName="ul">{`Email：wuxiaobai24#foxmail.com`}</li>
      <li parentName="ul">{`WeChat： d3V4aWFvYmFpMjQ=`}</li>
      <li parentName="ul">{`QQ：OTkyMDI5MDM2`}</li>
    </ul>
    <hr></hr>
    <h2>{`个人经历`}</h2>
    <h3>{`2015.9 - 2019.6（深圳大学）`}</h3>
    <ul>
      <li parentName="ul">{`学业之星（三等奖），专业前 6% | `}<strong parentName="li">{`2017 年 & 2018 年`}</strong></li>
      <li parentName="ul">{`CCF 计算机软件能力认证 | `}<strong parentName="li">{`220 分/前 13.16%`}</strong></li>
      <li parentName="ul">{`大学英语四级考试 | `}<strong parentName="li">{`四级 501 分`}</strong></li>
      <li parentName="ul">{`从光电工程学院转入计算机与软件学院 | `}<strong parentName="li">{`2016 年`}</strong></li>
    </ul>
    <ul>
      <li parentName="ul">{`大学平均绩点 | `}<strong parentName="li">{`3.88`}</strong></li>
      <li parentName="ul">{`专业排名 | `}<strong parentName="li">{`15/195`}</strong></li>
      <li parentName="ul">{`转入计算机与科学技术后平均绩点 | `}<strong parentName="li">{`4.08`}</strong></li>
    </ul>
    <h3>{`2019.9 至今（深圳大学）`}</h3>
    <ul>
      <li parentName="ul">{`以总评第一的成绩考入深圳大学软件工程专业`}</li>
    </ul>
    <hr></hr>
    <h2>{`Github`}</h2>

    <GithubCalendar username="wuxiaobai24" mdxType="GithubCalendar" />
    <hr></hr>
    <h2>{`技能清单`}</h2>
    <p>{`以下均为我熟悉的技能`}</p>
    <ul>
      <li parentName="ul">{`编程语言：C/C++/Python`}</li>
      <li parentName="ul">{`版本管理：Git`}</li>
      <li parentName="ul">{`计算机图形学：OpenGL`}</li>
      <li parentName="ul">{`操作系统：Linux`}</li>
    </ul>
    <hr></hr>
    <h2>{`项目经历`}</h2>
    <ul>
      <li parentName="ul">{`密码学：利用 n-gram 模型和爬山算法破解单表加密`}</li>
      <li parentName="ul">{`计算机图形学：利用 OpenGL 完成简单的俄罗斯方块`}</li>
    </ul>
    <hr></hr>
    <h2>{`致谢`}</h2>
    <p>{`感谢您花时间阅读我的简历，期待能有机会和您共事。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      